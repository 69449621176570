import Link from '@/components/link'
import { oss_svg_wanliyun_domain_address } from '@/constants/oss'
import styles from './index.module.css'

export function Logo() {
  return (
    <Link href="/" className={styles.scoped}>
      <img src={`${oss_svg_wanliyun_domain_address}image/logo.png`} alt={'Logo'} />
    </Link>
  )
}

export function SVGLogo() {
  return (
    <Link href="/" className={styles.scoped}>
      <div className="relative">
        <img src={`${oss_svg_wanliyun_domain_address}image/logo.png`} alt={'Logo'} />
      </div>
    </Link>
  )
}
