import { t } from '@lingui/macro'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { useRef } from 'react'
import { localeIsCnType } from '@/helper/common'
import { link } from '@/helper/link'
import { useLayoutStore } from '@/store/layout'
import jsonData from './bar.json'
import Styles from './index.module.css'
import { SVGLogo } from '../components/logo'

enum paramStr {
  serve = 'serve', // 服务条款 ID
  privacy = 'privacy', // 隐私策略 ID
  /**  web 显示 */
  isWebOk = 1,
  /** web 不显示 */
  isWebNo = 2,
}

// @ts-ignore
jsonData.assets[0].u = oss_svg_image_domain_address
// @ts-ignore
jsonData.assets[0].p = `default-loading.png`

type footerType = {
  webUrl?: string
  homeColumnName?: string
  isWeb?: string
}

function Footer() {
  const ref = useRef(null)
  const { setShowPlanFlag } = useLayoutStore()

  const navList = [
    {
      url: '/',
      name: t`features_layout_footer_index_be4hkvttte`,
    },
    {
      url: '/?tab=2',
      name: t`features_layout_header_pemdwkfxqg`,
    },
    {
      url: '/?tab=3',
      name: t`features_layout_header_d9zr7wem3g`,
    },
    {
      url: '/?tab=4',
      name: t`features_client_case_im_chat_components_advantage_xhizrsaaz5`,
    },
    {
      url: '/download',
      name: t`modules_home_download_index_page_server_ofezim5huv`,
    },
  ]

  return (
    <div className={Styles.scoped}>
      <div className="containers">
        <div className="footer-wrap">
          <div className="footer-logo">
            <span>
              <SVGLogo />
            </span>
            <div className="mobile-app-text">Apps</div>
            <div className="hidden lg:block mobile-box">
              <div className="footer-span">
                {navList.map((res, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        if (!res?.url) return
                        if (res?.url === 'plan') {
                          setShowPlanFlag(Date.now())
                          return
                        }
                        link(res?.url || '')
                      }}
                    >
                      {res?.name || ''}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bootom">
          <div
            className="mt-2 cursor-pointer lg:mt-0"
            onClick={() => {
              window.open(
                localeIsCnType()
                  ? 'https://docs.google.com/document/d/13YTCyG-O00KsSpckNeYpWCt2XtKHeKtwXYNSJeCPFxc'
                  : 'https://docs.google.com/document/d/1pboBvGHt5lugW2m9MxPJVdCnhlfmXyEmFE9NAwdSTNQ'
              )
            }}
          >
            ©2024 Wanliyu.{t`features_layout_footer_index_q_fnjfqzc5`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
