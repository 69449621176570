import Icon from '@/components/icon'
import { useState, useRef, useEffect } from 'react'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { Dropdown } from '@arco-design/web-react'
import { usePageContext } from '@/hooks/use-page-context'
import { useLayoutStore } from '@/store/layout'
import classNames from 'classnames'

import styles from './index.module.css'
import { I18nSelect } from './components/personalization'
import { SVGLogo } from './components/logo'

function DropDetail(pathName: string) {
  let pageContext = usePageContext()
  const imPath = `/${pathName}/im-chat`
  const wanliPath = `/${pathName}/wanli-sms`
  const cdnPath = `/${pathName}/cdn-domain`
  const unknownPath = `/${pathName}/unaccess`

  return (
    <div className={styles['drop-detail']}>
      <div className={classNames('item', { on: pageContext?.path === imPath })} onClick={() => link(imPath)}>
        IM {t`features_chain_star_wass_index_zyca8bzgwuc8bouzpqghm`}
      </div>
      <div className={classNames('item', { on: pageContext?.path === wanliPath })} onClick={() => link(wanliPath)}>
        {t`features_layout_header_pemdwkfxqg`}
      </div>
      <div className={classNames('item', { on: pageContext?.path === cdnPath })} onClick={() => link(cdnPath)}>
        {t`features_layout_header_d9zr7wem3g`}
      </div>
      <div className={classNames('item', { on: pageContext?.path === unknownPath })} onClick={() => link(unknownPath)}>
        {t`features_client_case_im_chat_components_advantage_xhizrsaaz5`}
      </div>
    </div>
  )
}

function DropSolution() {
  return (
    <div className={styles['drop-solution']}>
      <div className="plan-wrap">
        <div className="content">
          <div className="title">IM {t`features_chain_star_wass_index_zyca8bzgwuc8bouzpqghm`}</div>
          <div className="plan-list">
            <div className="item">
              <div className="tag-name">
                <Icon name="icon_audio_video" className="icon" />
                <span>{t`features_layout_header__hqaqncbvf`}</span>
              </div>
              <div className="desc">{t`features_layout_header_h0pmbvnnuy`}</div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_discover" className="icon" />
                <span>{t`features_layout_header_v0kxtj22nc`}</span>
              </div>
              <div className="desc">{t`features_layout_header_dxjojd4enr`}</div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_forward" className="icon" />
                <span>{t`features_layout_header_vxbogmfvrc`}</span>
              </div>
              <div className="desc">{t`features_layout_header_j54btbxth3`}</div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_private_chat" className="icon" />
                <span>{t`features_layout_header_xry5rqo9x_`}</span>
              </div>
              <div className="desc">{t`features_layout_header_55teb9ax95`}</div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_group_chat" className="icon" />
                <span>{t`features_layout_header_hplvid0yye`}</span>
              </div>
              <div className="desc">{t`features_layout_header_hgul7twwuj`}</div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_information" className="icon" />
                <span>{t`features_layout_header_fgvghkicgh`}</span>
              </div>
              <div className="desc">{t`features_layout_header_jdhhpgx_v_`}</div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="title">{t`features_layout_header_pemdwkfxqg`}</div>
          <div className="plan-list">
            <div className="item">
              <div className="tag-name">
                <Icon name="icon_cover" className="icon" />
                <span>{t`features_layout_header_g5pwd7bwdu`}</span>
              </div>
              <div className="desc">
                {t`features_layout_header_xhtwrdiexs`} {t`features_layout_header_wlldhgn1s2`} 200
                {t`features_layout_header_gj_pyw_ium`}
              </div>
            </div>

            <div className="item">
              <div className="tag-name">
                <Icon name="icon_any_signature" className="icon" />
                <span>{t`features_layout_header_t3sivyhr9f`}</span>
              </div>
              <div className="desc">{t`features_layout_header_aurw6m_srq`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Header() {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const herderDom = useRef<HTMLDivElement>(null)
  const pageContext = usePageContext()
  const path = pageContext?.path
  const { showPlanFlag } = useLayoutStore()
  const planRef = useRef<HTMLInputElement>(null)

  /**
   * 跳转事件
   */
  const onTab = url => {
    link(url)
  }

  useEffect(() => {
    if (showPlanFlag) {
      planRef?.current?.click()
    }
  }, [showPlanFlag])

  return (
    <div className={styles['header-menus-wrap']}>
      <div className="header-box" ref={herderDom}>
        <div
          className="home-wrap"
          onClick={() => {
            setShowMenu(false)
          }}
        >
          <SVGLogo />
          <div className="header-nav">
            <div className="home-header-left">
              <div className={classNames('item-text', { on: path === '/' })} onClick={() => onTab('/')}>
                {t`features_layout_header_belqrrslkz`}
              </div>

              <Dropdown droplist={DropDetail('client-case')} position="bottom">
                <div className={classNames('item-text', { on: path?.indexOf('/client-case') === 0 })}>
                  {t`features_home_components_one_stop_service_ebkoq4pfwa530a5_qenet`}
                  <Icon name="icon_drop_down" className="icon" />
                </div>
              </Dropdown>

              <Dropdown droplist={DropDetail('pricing')} position="bottom">
                <div className={classNames('item-text', { on: path?.indexOf('/pricing') === 0 })}>
                  {t`features_layout_header_y9wbgpbjdh`} <Icon name="icon_drop_down" className="icon" />
                </div>
              </Dropdown>

              <div className={classNames('item-text', { on: path === '/download' })} onClick={() => onTab('/download')}>
                {t`modules_home_download_index_page_server_ofezim5huv`}
              </div>

              <div
                className={classNames('item-text', { on: path?.indexOf('/product-doc') === 0 })}
                onClick={() => onTab('/product-doc')}
              >{t`features_layout_header_qmkk4_blot`}</div>
            </div>
            <div className="mobile-language">
              <I18nSelect popupAlign={{ bottom: 14, right: 0 }} />
              <Icon name="icon_h5_toolbar" onClick={() => setShowMenu(!showMenu)} className="icon-h5-toolbar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
