import { contactLink } from '@/constants/base'
import { oss_svg_wanliyun_domain_address } from '@/constants/oss'
import { t } from '@lingui/macro'
import styles from './index.module.css'

export default function FixedContact() {
  return (
    <div className={styles['fixed-contact']}>
      <a className="contact-main" href={contactLink} target="_blank">
        <div className="contact-icon">
          <img src={`${oss_svg_wanliyun_domain_address}image/fly.png`} alt={'contact-img'} />
        </div>
        <p className="contact-text">{t`features_layout_footer_5101300`}</p>
      </a>
    </div>
  )
}
